.contact-box {
 margin: 0;
 padding: 0;
 display: flex;
 justify-content: center;
 align-items: center;
 align-content: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;
  align-items: center;
}

.form{
  justify-content: center;
  padding: 0;
  margin: 0;
}

.close-button {

  background-color: transparent; /* Green */
  border: 2px solid black;
  color: black;
  padding: 1vh;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  margin: 0.5vh 0.2vw;
  cursor: pointer;
  border-radius: 50%;
  float: right;
  margin-right: -7vw;
}

.line-input{
  margin: 1vh ;
  font-family: 'Courier New', Courier, monospace;
  padding: 1vh 0.5vw;
  width: 20vw;
}
.multi-line-input{
  margin: 1vh;
  padding: 3vh 0.5vw;
  font-family: 'Courier New', Courier, monospace;
  width: 20vw;
}

.radio-buttons ul {
  list-style: none;
  display: flex;
  padding: 0vh 0.5vw;
  justify-content: space-around;
}
.radio-buttons label {
  display: inline-block;
  text-align: center;
  margin: 0 1rem;
}
.radio-buttons label input[type="radio"] {
  display: block;
  margin: 0 auto;
}
button {
    margin-top: 1%;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
}
.error{
  font-size: 1.8vh;
  color: red;
}
.submitted-message{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: green;
    font-style: italic;
    font-weight: bold;

}