.partners-main {
    display: flex;
    flex-direction: column;
    height: 50%;
    min-height: 50%;
    background-color: white;
    width: 100%;
}

.partners-row {
    display: flex;
    flex-flow: row wrap;
    overflow: auto;
    justify-content: space-around;
    justify-items: center;
    vertical-align: center;
    background-color: white;
    height: 100%;
    margin-bottom: 2%;
}

.partners-row img {
    height: 5rem;
}

.partners-h1 {
    display: flex;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .partners-row{
        flex-flow: row-reverse wrap;
    }
    .partners-row img {
        height: 30%;
        margin-left: 5%;
        margin-top: 5%;
    }


}
