/* Mobile Version Css */

.display-enter, .display-appear {
    width: 98vw;
    height: 94vh;
    top: 10vh;
}
.display-enter-active, .display-appear-active {

    transition: all 300s;
}

.testing-main {
    position: absolute;
    width: 98vw;
    height: 88vh;
    top: 10vh;
    z-index: 110;
    transform: scale(0);
    transition: transform 0.5s ease-in-out;
    background-color: white;
    border: 1px;
    box-shadow: 3px 0 17px rgba(0, 0, 0, 0.796);
    padding: 0;
    margin-left: 1%;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.testing-open {
    transform: scale(1);
}

.textual-content {
    margin: 1vh 5vw;
}

.testing-form {
    margin: 2vh 5vw;
}

.testing-main img{
    max-width: 75%;
    align-self: center;
 
}
.size-chart{
    display: flex;
    right: 2vw;
    top: 0;
    border: 1px solid black;
}
/* Desktop Version Css */
@media (min-width: 769px) {
    .testing-main {
        width: 70vw;
        top: 5rem;
        height: 88vh;
        margin-left: 15vw;
        margin-right: auto;;
        overflow: auto;
    }
    .testing-main img{
        max-width: 55%;
 
    }

}
  
 
  
  