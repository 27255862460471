.Nav{
    position: fixed;
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 10vh;
    width: 100%;
    z-index: 110;
    align-items: center;

}

.nav-logo {
    margin-left: 40px;
    align-items: center;
    cursor: pointer;
    margin-top: 2vh;
}

.spacer {
    display: flex;
    flex: 1;
}
.nav-bar-items {
    padding: 0 40px;
}
.nav-bar-items ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.nav-bar-items li {
    padding: 0 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.nav-bar-items li:hover{
    color: green;
    border-bottom: solid 1px green;
}

@media (max-width: 768px ){
    .nav-bar-items{
        display: none;
    }
}