.user-testing-content-main{
    height: 100%;
    width: 100%;
    position: static;
    margin-top:5%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
 
}
.images-para {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text-content{
    display: flex;
    align-items: center;
    
}
.content-box {
   margin: 0  5vw;
   display: flex;
   flex-direction: column;
}

.content-box img {
    max-width: 10vw;

}
.content-box h5{
    text-align: center;
}

.paragraph {
    display: flex;
    width: 30vw;
    height: 50vh;
    margin: 0 0;
    align-items: center;
    font-size: 2.5vh;
    text-align: center;
}

.paragraph p {
    font-size: 1.1rem;
}

.bottom-content{
    display: flex;
    flex-direction: row;
    margin-left: 5vw;
    margin-right: 0;
    margin-top: 3%;
    justify-content: space-between;
}
.bottom-content p{
    text-align: center;
    font-size: 1.1rem;
    padding: 0 2vh;
}
.main-col {
    display: flex;
    flex-direction: column;
    width: 60vw;
    justify-content: center;
    align-items: center;

}
.col-1{
    display: flex;
    flex-direction: row;
    padding-top: 10vh;
    margin-right: 5vw;
}
.col-2{
    display: flex;
    padding-top: 10vh;
    flex-direction: row;
    margin-right: 5vw;
}
.col-3{
    width: 40vw;
}
.col-3 img{
    max-width: 40vw;
}

@media (max-width: 769px){
    .user-testing-content-main{
        margin-top: 15%;
    }
    .images-para{
        margin-top: 5%;
        min-height: 90vh;
    }
    .text-content{
        position: static;
        display: flex;
        width: 100vw;
        height: 55%;
        min-height: 55%;
    }
    .paragraph {
        position: static;
        display: flex;
        width: 80vw;
        height: 45%;
        min-height: 45%;

    }
    .main-col{
        width: 100vw;
    }
    .bottom-content{
        position: static;
        min-height: 80vh;
    }
    .content-box h2 {
        font-size: 2vh;
    }
    .content-box {
        width: 90vw;
        padding: 1vh 0;
    }
    .content-box img{
        max-width: 30vw;
    }
    .bottom-content p{
        font-size: 2.2vh;
    }
    .col-1{
        padding-top: 0;
        justify-content: initial;
    }
    .col-2{
        padding-top: 0;
    }
    .col-3{
        display: none;
    }

}