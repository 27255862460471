@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cardo&display=swap');
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",Serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html , main{
  height: 100%;
  font-family: Nunito ,sans-serif;
}
html{

}
main:after { content:''; float:left;  }

footer { clear:left; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,"Courier New",
    monospace;
}
h1,h2,h3{
  font-family: 'Cardo', serif;
}
p,textarea,button{
  font-family: Nunito ,sans-serif;
}