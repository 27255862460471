.div-home-page {
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 10vh;
    overflow: auto;

}

.sock-beige-toe1-text {
    box-sizing: border-box;
    width: 50vw;
    right: 0;
}

.home-h2 {
    margin-top: 12vh;
    margin-right: 5vw;
    font-size: 3rem;
    text-align: right;
}

.sock-beige-toe1-text p {
    text-align: right;
    margin-right: 5vw;
}

.button-bestill {
    text-align: right;
    margin-right: 5vw;
}

.button-bestill button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.button-bestill button:hover {
    cursor: pointer;
}

.sock-beige-toe1 {
    display: flex;
    width: 50vw;
    margin-top: 4vh;
}

.sock-beige-toe1-img {
    height: 80vh;
    margin: 0 5vw;
}

.hidden {
    display: none;
    visibility: hidden;
}

.home-h3 {
display: flex;
    justify-content: flex-end;
    vertical-align: middle;
    max-height: 2rem;
    margin-right: 5vw;
}

.home-h3:hover {
    cursor: pointer;
    color: green;

}
.home-h3:hover svg {
    margin-left: 1.5rem;
    transition: 0.3s margin-left ease-in-out;
}

.see-more-svg path {
    fill: green;
    top: 0;
    transform: scale(0.25) ;
}
.home-h3 svg{
    max-height: 1.3rem;
    max-width: 1.4rem;
    margin-left: 1rem;
}



@media (max-width: 450px){

    .home-h2 {
        margin-right: 0;
        font-size: 2rem;

    }
    .home-text {
        margin-right: 0;
    }
    .home-h3{
        margin-right: 0;
    }
    .button-bestill{
        margin-right: 0;
    }
    .sock-beige-toe1-img {
        height: 68vh;
        margin: 6vh 3vw;
    }
}