@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cardo&display=swap);
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",Serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html , main{
  height: 100%;
  font-family: Nunito ,sans-serif;
}
html{

}
main:after { content:''; float:left;  }

footer { clear:left; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,"Courier New",
    monospace;
}
h1,h2,h3{
  font-family: 'Cardo', serif;
}
p,textarea,button{
  font-family: Nunito ,sans-serif;
}
.Nav{
    position: fixed;
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 10vh;
    width: 100%;
    z-index: 110;
    align-items: center;

}

.nav-logo {
    margin-left: 40px;
    align-items: center;
    cursor: pointer;
    margin-top: 2vh;
}

.spacer {
    display: flex;
    flex: 1 1;
}
.nav-bar-items {
    padding: 0 40px;
}
.nav-bar-items ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.nav-bar-items li {
    padding: 0 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.nav-bar-items li:hover{
    color: green;
    border-bottom: solid 1px green;
}

@media (max-width: 768px ){
    .nav-bar-items{
        display: none;
    }
}
.toogle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    margin-left: 20px;

}

.toogle-button :focus {
    outline: none;
}

.toogle-button__line {
    width: 30px;
    height: 2px;
    background: green;
}

@media (min-width: 769px){
    .toogle-button {
        display: none;
    }
}
.side-drawer {
    display: flex;
    height: 100vh;
    background: whitesmoke;
    box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70vw;
    z-index: 200;
    max-width: 70vw;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    align-items: center;
}

.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.list-items {
    display: flex;
    flex-direction: column;
    width: 70vw;
    max-width: 70vw;
}

.menu-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    max-width: 70vw;
    padding: 0;
}

.menu-list li {
    display: flex;
    justify-content: space-around;
    margin: 0.3vh 0;
    border: 0.1px hidden black;
    padding: 2vh 0;
    width: 70vw;
    background-color: lightgray;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 3vh;
}


@media (min-width: 768px) {
    .side-drawer {
        display: none;
    }
}
.backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 100;
}
.close {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;
}

.close :hover ::before, ::after {
    background-color: blueviolet;
}


.content {
    margin:10vh 0;
    overflow: auto;
}

.content p{
    text-align: justify;
    text-justify: inter-word;
    margin-left: 20vw;
    margin-right: 20vw;
    margin-top: 0;
}

.heading{
    margin-left: 20vw;
    margin-bottom: 0;
}
.content-button{
    margin-left: 20vw;
}
.mission{
    color: green;
    margin-left: 20vw;
    margin-bottom: 0;
}
.mission-text{
    font-style: italic;
}
.quote{
    margin-left: 5%;
    font-style: italic;
}
.quote-author{
    -moz-text-align-last: right;
         text-align-last: right;
    font-size: 1rem;
}

.about {
    display: flex;
    height: 90vh;
    width: 100vw;
    background-color: white;
    position: fixed;
    top: 10vh;
    left: 0;
    -webkit-transform: translateY(-120%);
            transform: translateY(-120%);
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    overflow: auto;
}

.about-open {

    background-color: white;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}


.button-up-to-close{
    display: flex;
    position: fixed;
    z-index: 500;
    cursor: pointer;
    bottom: 5vh;
    right: 5vh;
}
.hidden{
    display: none;
}
.contact-box {
 margin: 0;
 padding: 0;
 display: flex;
 justify-content: center;
 align-items: center;
 align-content: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;
  align-items: center;
}

.form{
  justify-content: center;
  padding: 0;
  margin: 0;
}

.close-button {

  background-color: transparent; /* Green */
  border: 2px solid black;
  color: black;
  padding: 1vh;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  margin: 0.5vh 0.2vw;
  cursor: pointer;
  border-radius: 50%;
  float: right;
  margin-right: -7vw;
}

.line-input{
  margin: 1vh ;
  font-family: 'Courier New', Courier, monospace;
  padding: 1vh 0.5vw;
  width: 20vw;
}
.multi-line-input{
  margin: 1vh;
  padding: 3vh 0.5vw;
  font-family: 'Courier New', Courier, monospace;
  width: 20vw;
}

.radio-buttons ul {
  list-style: none;
  display: flex;
  padding: 0vh 0.5vw;
  justify-content: space-around;
}
.radio-buttons label {
  display: inline-block;
  text-align: center;
  margin: 0 1rem;
}
.radio-buttons label input[type="radio"] {
  display: block;
  margin: 0 auto;
}
button {
    margin-top: 1%;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
}
.error{
  font-size: 1.8vh;
  color: red;
}
.submitted-message{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: green;
    font-style: italic;
    font-weight: bold;

}

/* Mobile Version Css */
.contact{
  position: absolute;
  height: 85vh;
  width: 98vw;
  top: 6vh;
  right: 1vw;
  z-index: 110;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  background-color: white;
  border: 1px;
  box-shadow: 3px 0px 17px rgba(0, 0, 0, 0.796);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 5%;
  overflow: auto;
}
 
.contact-open {
  -webkit-transform: scale(1);
          transform: scale(1)
}

.contact h1{
  padding: 0;
  margin: 0;
}

.contact p{
  text-align: center;
  padding: 0;
  margin: 1vh;
}

/* Desktop Version Css */
@media (min-width: 769px){
.contact {
  height: 85vh;
  width: 40vw;
  top: 6vh;
  right: 30vw;
  }
}

@media (max-width: 768px){
  .contact input{
    width: 95%;
  }
  .contact textarea{
    width: 95vw;
  }
}


.user-testing-content-main{
    height: 100%;
    width: 100%;
    position: static;
    margin-top:5%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
 
}
.images-para {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text-content{
    display: flex;
    align-items: center;
    
}
.content-box {
   margin: 0  5vw;
   display: flex;
   flex-direction: column;
}

.content-box img {
    max-width: 10vw;

}
.content-box h5{
    text-align: center;
}

.paragraph {
    display: flex;
    width: 30vw;
    height: 50vh;
    margin: 0 0;
    align-items: center;
    font-size: 2.5vh;
    text-align: center;
}

.paragraph p {
    font-size: 1.1rem;
}

.bottom-content{
    display: flex;
    flex-direction: row;
    margin-left: 5vw;
    margin-right: 0;
    margin-top: 3%;
    justify-content: space-between;
}
.bottom-content p{
    text-align: center;
    font-size: 1.1rem;
    padding: 0 2vh;
}
.main-col {
    display: flex;
    flex-direction: column;
    width: 60vw;
    justify-content: center;
    align-items: center;

}
.col-1{
    display: flex;
    flex-direction: row;
    padding-top: 10vh;
    margin-right: 5vw;
}
.col-2{
    display: flex;
    padding-top: 10vh;
    flex-direction: row;
    margin-right: 5vw;
}
.col-3{
    width: 40vw;
}
.col-3 img{
    max-width: 40vw;
}

@media (max-width: 769px){
    .user-testing-content-main{
        margin-top: 15%;
    }
    .images-para{
        margin-top: 5%;
        min-height: 90vh;
    }
    .text-content{
        position: static;
        display: flex;
        width: 100vw;
        height: 55%;
        min-height: 55%;
    }
    .paragraph {
        position: static;
        display: flex;
        width: 80vw;
        height: 45%;
        min-height: 45%;

    }
    .main-col{
        width: 100vw;
    }
    .bottom-content{
        position: static;
        min-height: 80vh;
    }
    .content-box h2 {
        font-size: 2vh;
    }
    .content-box {
        width: 90vw;
        padding: 1vh 0;
    }
    .content-box img{
        max-width: 30vw;
    }
    .bottom-content p{
        font-size: 2.2vh;
    }
    .col-1{
        padding-top: 0;
        justify-content: initial;
    }
    .col-2{
        padding-top: 0;
    }
    .col-3{
        display: none;
    }

}
.user-testing{
    height: 100%;
    width: 100vw;
    position: fixed;
    z-index: -100;
    bottom: 0;
    left: 0;
    background-color: white;
    -webkit-transition: -webkit-transform 0.4s ease-out;
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);

    
}
.user-testing-open {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
}
.button-down-to-close{
    display: flex;
    position: fixed;
    z-index: 500;
    cursor: pointer;
    top: 10vh;
    left: 5vh;
}
.hidden{
    display: none;
}

.user-testing img:hover{
    cursor: pointer;
}
.testing-form-main {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.testing-form {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.testing-form input {
    padding: 1.5vh;
    margin: 0.5vh 0vw;
}

.testing-form textarea {
    padding: 3vh 0.5vw;
    margin: 0.5vh 0vw;
}

.size-div select {
    margin: 0.2vh 0.5vw;
}

.size-div label {
    margin: 0.4vh 0.5vw;
}

.radio-buttons ul {
    list-style: none;
    display: flex;
    padding: 0vh 0.5vw;
    justify-content: space-around;
}

.radio-buttons label {
    display: inline-block;
    text-align: center;
    margin: 0 1rem;
}

.radio-buttons label input[type="radio"] {
    display: block;
    margin: 0 auto;
}
.error{
    font-size: 1.5vh;
    color: red;
}
.submitted-message{
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: green;
    font-style: italic;
    font-weight: bold;

}
/* Mobile Version Css */

.display-enter, .display-appear {
    width: 98vw;
    height: 94vh;
    top: 10vh;
}
.display-enter-active, .display-appear-active {

    -webkit-transition: all 300s;

    transition: all 300s;
}

.testing-main {
    position: absolute;
    width: 98vw;
    height: 88vh;
    top: 10vh;
    z-index: 110;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    background-color: white;
    border: 1px;
    box-shadow: 3px 0 17px rgba(0, 0, 0, 0.796);
    padding: 0;
    margin-left: 1%;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.testing-open {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.textual-content {
    margin: 1vh 5vw;
}

.testing-form {
    margin: 2vh 5vw;
}

.testing-main img{
    max-width: 75%;
    align-self: center;
 
}
.size-chart{
    display: flex;
    right: 2vw;
    top: 0;
    border: 1px solid black;
}
/* Desktop Version Css */
@media (min-width: 769px) {
    .testing-main {
        width: 70vw;
        top: 5rem;
        height: 88vh;
        margin-left: 15vw;
        margin-right: auto;;
        overflow: auto;
    }
    .testing-main img{
        max-width: 55%;
 
    }

}
  
 
  
  
.div-home-page {
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 10vh;
    overflow: auto;

}

.sock-beige-toe1-text {
    box-sizing: border-box;
    width: 50vw;
    right: 0;
}

.home-h2 {
    margin-top: 12vh;
    margin-right: 5vw;
    font-size: 3rem;
    text-align: right;
}

.sock-beige-toe1-text p {
    text-align: right;
    margin-right: 5vw;
}

.button-bestill {
    text-align: right;
    margin-right: 5vw;
}

.button-bestill button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.button-bestill button:hover {
    cursor: pointer;
}

.sock-beige-toe1 {
    display: flex;
    width: 50vw;
    margin-top: 4vh;
}

.sock-beige-toe1-img {
    height: 80vh;
    margin: 0 5vw;
}

.hidden {
    display: none;
    visibility: hidden;
}

.home-h3 {
display: flex;
    justify-content: flex-end;
    vertical-align: middle;
    max-height: 2rem;
    margin-right: 5vw;
}

.home-h3:hover {
    cursor: pointer;
    color: green;

}
.home-h3:hover svg {
    margin-left: 1.5rem;
    -webkit-transition: 0.3s margin-left ease-in-out;
    transition: 0.3s margin-left ease-in-out;
}

.see-more-svg path {
    fill: green;
    top: 0;
    -webkit-transform: scale(0.25) ;
            transform: scale(0.25) ;
}
.home-h3 svg{
    max-height: 1.3rem;
    max-width: 1.4rem;
    margin-left: 1rem;
}



@media (max-width: 450px){

    .home-h2 {
        margin-right: 0;
        font-size: 2rem;

    }
    .home-text {
        margin-right: 0;
    }
    .home-h3{
        margin-right: 0;
    }
    .button-bestill{
        margin-right: 0;
    }
    .sock-beige-toe1-img {
        height: 68vh;
        margin: 6vh 3vw;
    }
}
.main-video-container{
    display: flex;
    height: 102vh;
    width: 100vw;
}
.video-component{
    position: fixed;
    display: flex;
    background-color: black;
    width: 100vw;
    height: 102vh;
    align-items: center;
    align-content: center;
    justify-content: center;
}
::-webkit-scrollbar {
    display: none;
}

.open{
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.hidden{
    display: none;
    visibility: hidden;
}

.video-gif{
    display: none;
}

.video{
    top: 10%;
    height: 70%;
}

@media(max-width: 768px) {
    .video{
        display: none;
    }
    .video-gif{
        display: flex;
    }

}


.footer {
    width: 100vw;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    -webkit-transition: -webkit-transform 0.4s ease-out;
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.footer-content {
    display: flex;
    flex-direction: row;
    border-top: solid 1px lightgrey;
    height: 50%;
}

.column {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33%;
    text-align: left;
}

.footer-heading {
    margin-left: 15%;
}

.footer-text {
    margin-left: 15%;
    text-align: left;
}


.footer-button {
    margin-top: 1%;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
}

.footer-input {
    width: 80%;
    padding: 10px 10px;
}

button:hover {
    cursor: pointer;
}

.footer-social-icons {
    margin-top: 1%;
}

.footer-social-icons img {
    height: 2.5rem;
    margin-left: 2%;
    margin: 2vh 1vw;
}

.copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: lightgrey;

}

.copyright p {
    margin-top: 0;
}
.subscribe{
    display: flex;
}
.subscribe form {
    margin: 2vh 2vw; 

}

@media (max-width: 768px) {
    .footer{
        height: 90vh;
    }
.footer-content{
    height: 50%;
    overflow: auto;
    flex-direction: column;
}
.column{
    position: static;
    width: 98%;
    height: 90%;
    min-height: 90%;
}
}
.partners-main {
    display: flex;
    flex-direction: column;
    height: 50%;
    min-height: 50%;
    background-color: white;
    width: 100%;
}

.partners-row {
    display: flex;
    flex-flow: row wrap;
    overflow: auto;
    justify-content: space-around;
    justify-items: center;
    vertical-align: center;
    background-color: white;
    height: 100%;
    margin-bottom: 2%;
}

.partners-row img {
    height: 5rem;
}

.partners-h1 {
    display: flex;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .partners-row{
        flex-flow: row-reverse wrap;
    }
    .partners-row img {
        height: 30%;
        margin-left: 5%;
        margin-top: 5%;
    }


}


