.testing-form-main {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.testing-form {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.testing-form input {
    padding: 1.5vh;
    margin: 0.5vh 0vw;
}

.testing-form textarea {
    padding: 3vh 0.5vw;
    margin: 0.5vh 0vw;
}

.size-div select {
    margin: 0.2vh 0.5vw;
}

.size-div label {
    margin: 0.4vh 0.5vw;
}

.radio-buttons ul {
    list-style: none;
    display: flex;
    padding: 0vh 0.5vw;
    justify-content: space-around;
}

.radio-buttons label {
    display: inline-block;
    text-align: center;
    margin: 0 1rem;
}

.radio-buttons label input[type="radio"] {
    display: block;
    margin: 0 auto;
}
.error{
    font-size: 1.5vh;
    color: red;
}
.submitted-message{
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: green;
    font-style: italic;
    font-weight: bold;

}