
/* Mobile Version Css */
.contact{
  position: absolute;
  height: 85vh;
  width: 98vw;
  top: 6vh;
  right: 1vw;
  z-index: 110;
  transform: scale(0);
  transition: transform 0.2s ease-out;
  background-color: white;
  border: 1px;
  box-shadow: 3px 0px 17px rgba(0, 0, 0, 0.796);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border-radius: 5%;
  overflow: auto;
}
 
.contact-open {
  transform: scale(1)
}

.contact h1{
  padding: 0;
  margin: 0;
}

.contact p{
  text-align: center;
  padding: 0;
  margin: 1vh;
}

/* Desktop Version Css */
@media (min-width: 769px){
.contact {
  height: 85vh;
  width: 40vw;
  top: 6vh;
  right: 30vw;
  }
}

@media (max-width: 768px){
  .contact input{
    width: 95%;
  }
  .contact textarea{
    width: 95vw;
  }
}

