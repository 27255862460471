.main-video-container{
    display: flex;
    height: 102vh;
    width: 100vw;
}
.video-component{
    position: fixed;
    display: flex;
    background-color: black;
    width: 100vw;
    height: 102vh;
    align-items: center;
    align-content: center;
    justify-content: center;
}
::-webkit-scrollbar {
    display: none;
}

.open{
    transform: translateY(0);
}
.hidden{
    display: none;
    visibility: hidden;
}

.video-gif{
    display: none;
}

.video{
    top: 10%;
    height: 70%;
}

@media(max-width: 768px) {
    .video{
        display: none;
    }
    .video-gif{
        display: flex;
    }

}
