.user-testing{
    height: 100%;
    width: 100vw;
    position: fixed;
    z-index: -100;
    bottom: 0;
    left: 0;
    background-color: white;
    transition: transform 0.4s ease-out;
    transform: translateY(100vh);

    
}
.user-testing-open {
    transform: translateY(0vh);
}
.button-down-to-close{
    display: flex;
    position: fixed;
    z-index: 500;
    cursor: pointer;
    top: 10vh;
    left: 5vh;
}
.hidden{
    display: none;
}

.user-testing img:hover{
    cursor: pointer;
}