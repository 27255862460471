.close {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;
}

.close :hover ::before, ::after {
    background-color: blueviolet;
}


.content {
    margin:10vh 0;
    overflow: auto;
}

.content p{
    text-align: justify;
    text-justify: inter-word;
    margin-left: 20vw;
    margin-right: 20vw;
    margin-top: 0;
}

.heading{
    margin-left: 20vw;
    margin-bottom: 0;
}
.content-button{
    margin-left: 20vw;
}
.mission{
    color: green;
    margin-left: 20vw;
    margin-bottom: 0;
}
.mission-text{
    font-style: italic;
}
.quote{
    margin-left: 5%;
    font-style: italic;
}
.quote-author{
    text-align-last: right;
    font-size: 1rem;
}