
.about {
    display: flex;
    height: 90vh;
    width: 100vw;
    background-color: white;
    position: fixed;
    top: 10vh;
    left: 0;
    transform: translateY(-120%);
    transition: transform 0.4s ease-in-out;
    overflow: auto;
}

.about-open {

    background-color: white;
    transform: translateY(0);
}


.button-up-to-close{
    display: flex;
    position: fixed;
    z-index: 500;
    cursor: pointer;
    bottom: 5vh;
    right: 5vh;
}
.hidden{
    display: none;
}