
.footer {
    width: 100vw;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    transition: transform 0.4s ease-out;
    transform: translateY(0);
}

.footer-content {
    display: flex;
    flex-direction: row;
    border-top: solid 1px lightgrey;
    height: 50%;
}

.column {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33%;
    text-align: left;
}

.footer-heading {
    margin-left: 15%;
}

.footer-text {
    margin-left: 15%;
    text-align: left;
}


.footer-button {
    margin-top: 1%;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
}

.footer-input {
    width: 80%;
    padding: 10px 10px;
}

button:hover {
    cursor: pointer;
}

.footer-social-icons {
    margin-top: 1%;
}

.footer-social-icons img {
    height: 2.5rem;
    margin-left: 2%;
    margin: 2vh 1vw;
}

.copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: lightgrey;

}

.copyright p {
    margin-top: 0;
}
.subscribe{
    display: flex;
}
.subscribe form {
    margin: 2vh 2vw; 

}

@media (max-width: 768px) {
    .footer{
        height: 90vh;
    }
.footer-content{
    height: 50%;
    overflow: auto;
    flex-direction: column;
}
.column{
    position: static;
    width: 98%;
    height: 90%;
    min-height: 90%;
}
}