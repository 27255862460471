.side-drawer {
    display: flex;
    height: 100vh;
    background: whitesmoke;
    box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70vw;
    z-index: 200;
    max-width: 70vw;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    align-items: center;
}

.open {
    transform: translateX(0);
}

.list-items {
    display: flex;
    flex-direction: column;
    width: 70vw;
    max-width: 70vw;
}

.menu-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    max-width: 70vw;
    padding: 0;
}

.menu-list li {
    display: flex;
    justify-content: space-around;
    margin: 0.3vh 0;
    border: 0.1px hidden black;
    padding: 2vh 0;
    width: 70vw;
    background-color: lightgray;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 3vh;
}


@media (min-width: 768px) {
    .side-drawer {
        display: none;
    }
}